<template>
        <section>
            <v-parallax :src="require(`../../../../../assets/images/sacco.png`)" height="600" class="home-parallax cross-border" align-center justify-center  :class="{'mobile-layout' :is_screen_small}">
                <v-layout>
                    <v-row no-gutters>
                        <v-col lg="7" md="7" sm="12" xs="12">
                            <v-card height="100%" flat tile xs12 sm12  width="100%" column align="left" justify="left"  class="white--text parallax-text-holder transparent">
                                <v-card-text class="white--text subheading mb-3 text-xs-center parallax-text parralax-left">
                                    <span class="zamu-heading slider-title happiness-orange">ZAMUPAY FOR SACCOS </span>
                                  <span class="zamu-sub-heading trail-text happiness-white">Payment solutions </span>
                                  <span class="zamu-heading happiness-text happiness-orange">TO ELEVATE SACCOs' </span>
                                  <span class="zamu-heading happiness-text mb-3 happiness-white"  :class="{'hide' :is_screen_small}" > service provision </span>

                                  <span class="image-text my-8 happiness-white">
                                 Kenya has one of the most robust and dynamic SACCO (Savings & Credit Cooperatives) sub-sectors in Africa and indeed, the world. Over the years, SACCOs have grown to become key intermediators in the financial services space
                                  </span>
                                  <span class="" justify="space-around mb-8" :class="{'hide' :is_screen_small}">
                                      <v-btn large  depressed :color="`${zamuYellow3}`" class="mr-3 started-btn" href="/contact-sales">
                                            Get in Touch
                                        </v-btn>
                                  </span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col lg="5" md="5" sm="12" xs="12" class="hidden-sm-and-down">
                            <v-card flat tile height="100%"  width="70%" column align="right" justify="right"  class="white--text parallax-text-holder transparent">
                                <v-card-text class="white--text subheading text-xs-center parallax-text parallax-image" style="font-weight: 900; text-shadow: 2px 2px #000000;">
                                    <v-card tile flat class="image-holder transparent">
                                        <img :src="`${s3Path}purpleBlur.png`" height="200" class="">
                                        <img :src="`${s3Path}purpleRabbit.png`"  height="200" width="200" class="rabbit-img">
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-parallax>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name:'SaccoSlider',
    mixins: [colorMixin, coreMixin],
    computed: {
        is_screen_small() {
        return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly
    },
    }

}
</script>
<style>
  .cross-border  .v-parallax__content {
    background-image:
    linear-gradient(to right,  rgb(68 36 124), rgba(245, 246, 252, 0.52)),
    url('../../../../../assets/images/sacco.png');
    background-size: cover;

    }
</style>

<style scoped>
.slider-title {
    color: #43237d;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 70px;
    text-transform: uppercase;
    display: block;
}
.trail-text {
    color: #d29f2a !important;
    font-size: 49px !important;
}
.happiness-text {
    text-transform: uppercase !important;
    font-size: 50px !important
}
.happiness-white {
    color: #ffffff !important;
}
.home-parallax {
    height: 600px !important;
}
.mobile-layout {
    height: 700px !important;

}
</style>